import React, { Component }  from 'react';
import './app-icon.css';

class AppIcon extends Component {
  render() {
    return (
      <div className='icon-container' onClick={this.props.housesClicked}>
        <div className='houses-container'>
          <div className='hithere'><img src={require('./images/yellow-house.png')} alt=""/></div>
          <div className='hithere'><img src={require('./images/blue-house.png')} alt=""/></div>
        </div>
        <div>
          <img className='platform' src={require('./images/platform.png')} alt=""/>
        </div>
      </div>
    );
  }
}

export default AppIcon;