import React, { Component }  from 'react';
import './possibles-buttons.css';

export default class PossiblesButtons extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const possibleResponseId = event.target.getAttribute('possibleresponseid');
    let selected = this.props.responses.possibles[possibleResponseId];
    this.props.responseSelected({
      ...event,
      response: selected.content
    });
  }

  render() {
    if (this.props.responses === null) {
      return null;
    }

    let buttons = [];
    for (const responseId in this.props.responses.possibles) {
      buttons.push(<button key={`possible-buttons-${responseId}`} type='button' possibleresponseid={responseId} onClick={this.handleClick} className='possible-button'>{this.props.responses.possibles[parseInt(responseId)].content}</button>)
    }
    buttons = buttons.sort((b1, b2) => this.props.responses.possibles[b1.props.possibleresponseid].sortOrder - this.props.responses.possibles[b2.props.possibleresponseid].sortOrder);
    return (
      <div className='response-container'>
        <div className='buttons-container'>
          {buttons}
        </div>
      </div>
    );  
  }
}

