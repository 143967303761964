import React, { Component } from 'react';

class LeadForm extends Component  {
  constructor(props) {
    super(props);

    this.state = { 
      page: props.style === 'inline_form' ? 1 : null
    };
    this.fullName = React.createRef();
    this.email = React.createRef();
    this.phone = React.createRef();
    this.address1 = React.createRef();
    this.address2 = React.createRef();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress(this);
  }

  componentDidMount() {
    import('./lead-form.css');
  }

  handleFormSubmit(event) {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const address1 = this.address1.current?.value ?? null;
    const address2 = this.address2.current?.value ?? null;
    const firstSpace = this.fullName.current.value.indexOf(' ') ?? null;
    const firstName = this.fullName.current.value.substring(0, firstSpace);
    const lastName = firstSpace !== null ? this.fullName.current.value.substring(firstSpace + 1) : null;
    let expression = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailAddress = this.email.current.value.match(expression);
    emailAddress = emailAddress !== null ? emailAddress[0] : null;
    expression = /^1?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let phoneNumber = this.phone.current.value.match(expression);
    if (phoneNumber !== null && phoneNumber.length === 4) {
      phoneNumber = `${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`;
    }
    else {
      phoneNumber = null;
    }
    const response = {
      address1: address1,
      address2: address2,
      firstName,
      lastName: lastName,
      email: emailAddress,
      phone: phoneNumber
    };
    let isComplete = true;
    if (this.state.page === 1) {
      this.setState({ page: 2 });
      isComplete = false;
    }
    this.props.saveResponse(response, isComplete);
  }

  handleKeyPress(event) {
    if (event.target === undefined) {
      return;
    }
    event.target.parentNode.dataset.replicatedValue = event.target.value;

    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      event.target.form[1].click();
      event.target.parentNode.dataset.replicatedValue = event.target.value = '';
    }
  }

  closeForm() {
    window.parent?.postMessage({
      type: 'eppraisal',
      class: 'closed_form',
      datetime: new Date()
    }, '*');
  }

  render() {
    let className = `${this.props.style} wrapper`;
    if (this.state.page === 1) {
      className = `${className} page_1`;
    }
    else if (this.state.page === 2) {
      className = `${className} page_2`;
    }
    return (
      <div className={className}>
        {this.props.style === 'overlay_form' ? <input type='button' value='X' className='close-button' onClick={this.closeForm} /> : ''}
        <h2>Sign Up to get Your Estimate</h2>
        <form>
        <div className={this.state.page !== 2 ? 'input-box' : 'hide'}>
            <input ref={this.fullName} type='text' placeholder='Enter your Name' required />
          </div>
          <div className={this.state.page !== 2 ? 'input-box' : 'hide'}>
            <input ref={this.email} type='email' placeholder='Email Address' required />
          </div>
          <div className={this.state.page !== 2 ? 'input-box' : 'hide'}>
            <input ref={this.phone} type='tel' placeholder='Phone Number' required />
          </div>
          <div className={this.state.page !== 1 ? 'input-box' : 'hide'}>
            <input ref={this.address1} type='text' placeholder='Property Street Address' required />
          </div>
          <div className={this.state.page !== 1 ? 'input-box' : 'hide'}>
            <input ref={this.address2} type='text' autoComplete='postal-code' placeholder='Property Zip or City, State' onKeyDown={this.handleKeyPress} required />
          </div>
          <div className='input-box button'>
            <input type='Submit' value={this.state.page === 1 ? 'Next' : 'Sign Up Now'} readOnly onClick={this.handleFormSubmit} onKeyDown={this.handleKeyPress} />
          </div>
        </form>
        <aside>
          <span>powered by Eppraisal</span>
        </aside>
      </div>
    );
  }
}

export default LeadForm;