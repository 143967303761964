import React, { Component } from 'react';
import Chatbot from './chatbot';
import ContactForm from './contact-form';
import ReCAPTCHA from 'react-google-recaptcha';

import './App.css';

class App extends Component  {

  constructor(props) {
    super(props);

    this.state = {
      token: null,
      settings: null,
      isExecutingRecaptcha: false,
      captchaToken: null,
      responses: null
    };

    this.reCaptcha = React.createRef();
    this.getSettings = this.getSettings.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
    this.executeRecaptcha = this.executeRecaptcha.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    this.restart = this.restart.bind(this);
  }

  async getSettings(interviewee) {
    if (this.props.appKey === undefined || this.props.appKey === null) {
      return null;
    }
    const self = this;
    return await fetch(`${process.env.REACT_APP_API}/getSettings`, {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${window.btoa(`${this.props.appKey}:`)}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ interviewee })
    })
    .then((result) => {
      if (result.status === 200) {
        return result.json();
      }
      return null;
    })
    .then(result => {
      if (result === null) {
        return;
      }
      this.setState({
        token: result.token,
        settings: result.settings
      });
      const style = (self.props.style ?? result.settings.style);
      window.parent?.postMessage({
        type: 'eppraisal',
        class: style,
        datetime: new Date()
      }, '*');
    })
    .catch((error) => {
    });
  }

  async saveResponse(response) {
    const body = {
      conversationId: this.state.responses?.conversationId ?? null,
      interviewee: this.props.userId,
      response
    };
    if (body.conversationId === null) {
      await fetch(`${process.env.REACT_APP_API}/respond`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.state.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(body)
      })
      .then(r => r.json())
      .then(r => {
        this.setState(state => ({
          responses: state.responses === null ? { conversationId: r.id, ...response } : { conversationId: r.id, ...state.responses, ...response }
        }));
      });
    }
    else {
      this.setState(state => ({
        responses: state.responses === null ? response : { ...state.responses, ...response }
      }));
      fetch(`${process.env.REACT_APP_API}/respond`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${this.state.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(body)
      });
    }
  }

  executeRecaptcha() {
    if (this.state.isExecutingRecaptcha === false) {
      this.reCaptcha.current.execute();
      this.setState({ isExecutingRecaptcha: true, captchaToken: null });
    }
  }

  handleCaptchaChange(token) {
    this.setState({ isExecutingRecaptcha: false, captchaToken: token });
  }

  componentDidMount() {
    if (this.state.token === null || this.state.settings === null) {
      this.getSettings(this.props.userId);
    }
  }

  closeForm(event) {
    if (event.target.id !== 'container') {
      return;
    }

    window.parent?.postMessage({
      type: 'eppraisal',
      class: 'closed_form',
      datetime: new Date()
    }, '*');
  }
  
  restart() {
    const style = (this.props.style ?? this.state.settings?.style);
    this.setState({ responses: null });
    window.parent?.postMessage({
      type: 'eppraisal',
      class: style,
      datetime: new Date()
    }, '*');
  }

  render() {
    const settings = this.state.settings !== null ? { ...this.state.settings, style: this.props.style ?? this.state.settings.style } : null;
    if (settings === null) {
      return null;
    }
    return (<div id='container' onClick={this.closeForm}>
        {settings.style.indexOf('greeter') < 0 ?
          (<ContactForm settings={settings} interviewee={this.props.userId} appKey={this.props.appKey}
            responses={this.state.responses} captchaToken={this.state.captchaToken} token={this.state.token}
            executeRecaptcha={this.executeRecaptcha} saveResponse={this.saveResponse} restart={this.restart} />)
          :
          (<Chatbot settings={settings} interviewee={this.props.userId} responses={this.state.responses}
            appKey={this.props.appKey} captchaToken={this.state.captchaToken} token={this.state.token}
            executeRecaptcha={this.executeRecaptcha} saveResponse={this.saveResponse} restart={this.restart} />)}
        <ReCAPTCHA ref={this.reCaptcha} size='invisible' badge='inline' sitekey='6LdysN4cAAAAAKDU_Zokqf23RDTgui2PM8T8w7qA' onChange={this.handleCaptchaChange} />
      </div>
    );
  }
}

export default App;