import React, { Component }  from 'react';
import Valuation from './valuation';
import RenovationProjects from './reno-projects';
import StatementLink from './statement-link';
import LeadForm from './lead-form';
import './contact-form.css';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1
    }
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleRenoIdeas = this.handleRenoIdeas.bind(this);
    this.handleMoreInfo = this.handleMoreInfo.bind(this);
    this.restart = this.restart.bind(this);
  }

  handleFormSubmit(response, isComplete) {
    if (isComplete === true) {
      this.setState({ page: 2 });
    }
    this.props.saveResponse(response);
  }

  handleKeyPress(event) {
    if (event.target === undefined) {
      return;
    }
    event.target.parentNode.dataset.replicatedValue = event.target.value;

    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      event.target.form[1].click();
      event.target.parentNode.dataset.replicatedValue = event.target.value = '';
    }
  }

  handleRenoIdeas(event) {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    this.setState({ page: 3 });
  }

  handleMoreInfo(event) {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    this.setState({ page: 4 });
  }

  restart() {
    this.props.restart();
    this.setState({ page: 1});
  }

  render() {
   switch (this.state.page) {
    case 1:
      return <LeadForm interviewee={this.props.userId} style={this.props.settings.style} saveResponse={this.handleFormSubmit} />;
    case 2:
      return (
        <div className='wrapper'>
          <Valuation address1={this.props.responses?.address1 ?? null} address2={this.props.responses?.address2 ?? null}
            style={this.props.settings.style} appKey={this.props.appKey} captchaToken={this.props.captchaToken} executeRecaptcha={this.props.executeRecaptcha}
            valuation={this.props.responses?.valuation ?? null} saveResponse={this.props.saveResponse} />
            <form>
              <div className='input-box button'>
              { this.props.settings.showRenoIdeas === false ?
                <input type='Submit' value='More Property Info?' onClick={this.handleMoreInfo} onKeyDown={this.handleKeyPress} /> :
                <input type='Submit' value='Get Renovatioin Ideas' onClick={this.handleRenoIdeas} onKeyDown={this.handleKeyPress} /> }
              </div>
            </form>
            <aside>
              <span>powered by Eppraisal</span>
            </aside>
        </div>
      );
    case 3:
      return (
        <div className='wrapper'>
          <RenovationProjects address1={this.props.responses?.address1 ?? null} address2={this.props.responses?.address2 ?? null}
            style={this.props.settings.style} appKey={this.props.appKey} captchaToken={this.props.captchaToken} executeRecaptcha={this.props.executeRecaptcha}
            projects={this.props.responses?.projects ?? null} saveResponse={this.props.saveResponse} />
          <form>
            <div className='input-box button'>
              <input type='Submit' value='More Property Info?' onClick={this.handleMoreInfo} onKeyDown={this.handleKeyPress} />
            </div>
          </form>
          <aside>
            <span>powered by Eppraisal</span>
          </aside>
        </div>
       );
    case 4:
      return (
        <div className='wrapper form'>
          <StatementLink appKey={this.props.appKey} messageType='links_valuation_projects_mortgage'
            responses={this.props.responses} interviewee={this.props.interviewee} settings={this.props.settings}
            content='Okay. Thanks for stopping by. Here are some helpful resources:' saveResponse={this.props.saveResponse} />
          <button type='button' className="linkButton" onClick={this.restart}>Restart</button>
          <aside>
            <span>powered by Eppraisal</span>
          </aside>
        </div>
      );
    default:
      return null;
   }
  }
}

export default ContactForm;