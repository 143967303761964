import React, { Component } from 'react';
import AppIcon from './app-icon';
import ChatWindow from './chat-window';
import './chatbot.css';

class Chatbot extends Component  {
  constructor(props) {
    super(props);

    this.state = {
      conversation: null,
      currentMessageId: -1
    }

    this.chatWindow = React.createRef();
    this.appIcon = React.createRef();
    this.fetchDefaultConvo = this.fetchDefaultConvo.bind(this);
    this.toggleWindow = this.toggleWindow.bind(this);
    this.handleExternalDismiss = this.handleExternalDismiss.bind(this);
    this.saveResponse = this.saveResponse.bind(this);
  }

  fetchDefaultConvo() {
    fetch('messages-default.json')
    .then(r => r.json())
    .then(r => {
      if (this.props.settings?.showRenoIdeas === false) {
        r.messages['-1'].content = 'Welcome! Would you like to receive your estimated home valuation?';
        r.messages['-1'].responses.possibles = {
          "-1": {
            "content": "yes",
            "nextMessageId": -5,
            "sortOrder": 1
          },
          "-3": {
            "content": "no",
            "nextMessageId": -26,
            "sortOrder": 2
          }
        };
      }
      if (this.props.settings?.showMortgageInfo === false) {
        r.messages['-4'].nextMessageId = -17;
        r.messages['-7'].nextMessageId = -27;
        r.messages['-10'].nextMessageId = -36;
      }
      this.setState({ currentMessageId: -1, conversation: r });
    });
  }

  toggleWindow() {
    if (this.state.conversation?.messages[this.state.currentMessageId].nextMessageId === null &&
      this.state.conversation?.messages[this.state.currentMessageId].responses.possibles === null) {
      this.fetchDefaultConvo();
      this.props.restart();
    }
    this.chatWindow.current.toggleWindow();
  }

  handleExternalDismiss(message) {
    if (message.data.type === 'eppraisal' && message.data.class === 'closed_greeter' && this.chatWindow.current?.isOpen() === true) {
      this.toggleWindow();
    }
  }

  async saveResponse(response) {
    let nextMessageId = this.state.conversation.messages[this.state.currentMessageId].nextMessageId;
    if (nextMessageId === null) {
      const possibles = this.state.conversation.messages[this.state.currentMessageId].responses.possibles;
      if (possibles !== null) {
        let possibleResponseId = Object.keys(possibles).find(pid => possibles[pid].content.toLowerCase() === response.toLowerCase()) ?? null;
        if (possibleResponseId !== null) {
          nextMessageId = possibles[possibleResponseId].nextMessageId;
        }
      }  
    }

    let nextMessageType = nextMessageId !== null ? this.state.conversation.messages[nextMessageId].type : null;
    this.setState(state => {
      let convo = state.conversation;
      if (nextMessageId !== null) {
        convo.messages[state.currentMessageId].responses.actual = {
          content: typeof response === 'object' ? Object.values(response).reduce((prev,cur) => `${prev} ${cur}`) : response,
          nextMessageId
        };
      }
      return {
        currentMessageId: nextMessageId ?? state.currentMessageId,
        conversation: convo,
      };
    });
    if (response !== null && typeof response === 'object') {
      await this.props.saveResponse(response); 
    }

    return nextMessageType;
  }

  componentDidMount() {
    this.fetchDefaultConvo();
    if (this.props.settings?.style === 'open_greeter') {
      this.toggleWindow();
    }
    window.addEventListener('message', this.handleExternalDismiss);
  }

  render() {
    return (<div className='chatbot'>
      <ChatWindow ref={this.chatWindow} interviewee={this.props.interviewee} conversation={this.state.conversation}
        currentMessageId={this.state.currentMessageId} appKey={this.props.appKey} responses={this.props.responses}
        captchaToken={this.props.captchaToken} token={this.props.token} settings={this.props.settings}
        executeRecaptcha={this.props.executeRecaptcha} saveResponse={this.saveResponse} />
      <AppIcon housesClicked={this.toggleWindow} />
    </div>);
  }
}

export default Chatbot;