import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { v4 as uuidv4 } from "uuid";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
let userId = document.cookie
  .split('; ')
  .find(row => row.startsWith('user_id='))
  ?.split('=')[1];
if (userId === undefined || userId === null) {
  userId = uuidv4();
  const d = new Date();
  d.setTime(d.getTime() + (30*24*60*60*1000));
  const expires = 'expires='+ d.toUTCString();
  document.cookie = `user_id=${userId};${expires};`;
}
if (params.demo !== 'true') {
  const gtagScript = document.createElement('script');
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-NSCHT9JWX5';
  gtagScript.async = true;
  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-NSCHT9JWX5', {
    user_id: '${params.appKey}'
  });`;
  document.head.appendChild(gtagScript);
  document.head.appendChild(inlineScript);
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App appKey={params.appKey} userId={userId} style={params.style} />);
