import React, { Component }  from 'react';
import './statement-link.css';

export default class StatementLink extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this); 
  }

  handleClick(e) {
    const ctatype = e.target.getAttribute('ctatype');
    let ctaURL = null;
    switch (ctatype) {
      case 'valuation':
        const search = {
          address1: this.props.responses.address1,
          address2: this.props.responses.address2,
          interviewee: this.props.interviewee,
          source: 'greeter',
          conversationId: this.props.responses.conversationId,
          appKey: this.props.appKey
        };
        ctaURL = `${process.env.REACT_APP_BLOG}/property/?search=${btoa(JSON.stringify(search))}`;
        break;
      case 'projects':
        ctaURL = JSON.parse(this.props.responses.projects).ctaURL ?? null;
        break;
      case 'mortgage':
        ctaURL = this.props.settings.mortgagePartnerURL ?? `${process.env.REACT_APP_BLOG}/mortgage-center/`;
        break;
      default:
        break;
    }
    window.open(ctaURL);
    let link = this.props.responses?.links !== undefined ? this.props.responses?.links[ctatype] : undefined;
    if (link !== undefined) {
      link.clicks++;
      this.props.saveResponse({ links: this.props.responses.links });
    }
    else {
      link = {};
      link[ctatype] = { url: ctaURL, clicks: 1 };
      const links = this.props.responses?.links !== undefined ? {...this.props.responses.links , ...link}: link;
      this.props.saveResponse({ links });
    }
  }

  componentDidMount() {
    this.props.saveResponse({ isFinished: true });
    window.parent?.postMessage({
      type: 'eppraisal',
      class: `${this.props.settings.style.indexOf('greeter') < 0 ? this.props.settings.style : 'open_greeter'} statement`,
      datetime: new Date()
    }, '*');
  }

  render() {
    return (<div className='message-container'>
      <div className='message'>
        {this.props.content}
        <br />
        {this.props.messageType.split('_').splice(1).map((link, index) => {
          let button = null;
          switch (link) {
            case 'valuation':
              if ((this.props.responses?.valuation ?? null) === null) {
                return null;
              }
              button = <button key='propBtn' ctatype='valuation' type='button'
                className="linkButton" onClick={this.handleClick}>Property Details</button>;
            break;
            case 'projects':
              if ((this.props.responses?.projects ?? null) === null || this.props.settings?.showRenoIdeas === false) {
                return null;
              }
              button =  <button key='projects' ctatype='projects' type='button'
                className="linkButton" onClick={this.handleClick}>More Remodeling Ideas</button>;
              break;
            case 'mortgage':
              if (this.props.settings?.showMortgageInfo === false) {
                return null;
              }
              button = <button key='mortgage' ctatype='mortgage'
                type='button' className="linkButton" onClick={this.handleClick}>Mortgage Center</button>;
              break;
            default:
              break;
          }
          return <div key={index}><span>&#10146;</span>{button}</div>;
        })
        }
      </div>
    </div>); 
  }
}