import React, { Component }  from 'react';
import './valuation.css';

export default class Valuation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      attempts: 0,
      isWorking: false,
      isComplete: false,
      valuation: null
    };
    this.fetchValuation = this.fetchValuation.bind(this);
  }

  fetchValuation() {
    if (this.state.isWorking === true) {
      return;
    }
    this.setState(state => ({ isWorking: true, attempts: state.attempts + 1 }));
    let body = {
      address1: this.props.address1,
      address2: this.props.address2,
      captchaToken: this.props.captchaToken
    };
    let thisComponent = this;
    fetch(`${process.env.REACT_APP_API}/propertyAVM`, {
      method: 'POST',
      headers: {
        'Authorization': `Basic ${window.btoa(`${this.props.appKey}:`)}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then((result) => {
      switch (result.status) {
        case 200:
          return result.json();
        case 204:
            return null;
        case 405:
          if (thisComponent.state.attempts < 3) {
            thisComponent.props.executeRecaptcha();
          }
          else {
            return null;
          }
          break;
        default:
          break;
      }
    })
    .then((result) => {
      if (result === undefined) {
        thisComponent.setState({ isWorking: false });
        return;
      }
      let valuation = null;
      let jsonValue = null;
      if (result !== null) {
        valuation = {
          avmValue: result.avm,
          avmLow: result.avmLow,
          avmHigh: result.avmHigh
        };
        jsonValue = JSON.stringify(valuation);
      }
      thisComponent.setState({ isWorking: false, isComplete: true, valuation: jsonValue });
      thisComponent.props.saveResponse({ valuation: jsonValue });
      if (window.gtag !== undefined) {
        window.gtag('event', 'page_view', {
          page_location: window.location + '/valuation',
          page_title: 'valuation'
        });
      }
    });
  }

  componentDidMount() {
    if (this.props.style?.indexOf('greeter') < 0) {
      window.parent?.postMessage({
        type: 'eppraisal',
        class: `${this.props.style} valuation`,
        datetime: new Date()
      }, '*');
    }
    if (this.props.address1 === null || this.props.address2 === null || this.state.isWorking === true ||
      this.state.attempts >= 3 || this.props.valuation !== null) {
      return;
    }
    else if (this.state.attempts < 3 && this.props.captchaToken === null) {
      this.props.executeRecaptcha();
      return;
    }

    this.fetchValuation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.address1 === null || this.props.address2 === null || this.state.isWorking === true ||
      this.state.attempts >= 3 || this.state.valuation !== null
    ) {
      return;
    }
    else if (this.state.attempts < 3 && this.props.captchaToken === null) {
      this.props.executeRecaptcha();
      return;
    }

    this.fetchValuation();
  }

  render() {
    let valuation = this.props.valuation ?? this.state.valuation;
    let range = null;
    let value = null;
    let valueRange = null;
    if (valuation !== null) {
      valuation = JSON.parse(valuation);
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      range = `${formatter.format(valuation.avmLow)} - ${formatter.format(valuation.avmHigh)}`;
      value = formatter.format(valuation.avmValue);
      valueRange = (<div className='valueRange'>
        <div className='value'>
          <h2>{value}</h2>
        </div>
        <div className='range'>
          <h1>{range}</h1>
        </div>
      </div>);
    }
    else if (this.state.isComplete === true && this.state.valuation === null) {
      valueRange = (<div><span>Unfortunately, we can't find your property in our database.</span></div>);
    }

    return (
      <div>
        <div className={this.state.isComplete === false ? 'message-container valuation is-working' : 'message-container valuation'}>
          <div className='header'>
            <aside className='housesContainer'>
              <img src={`${require('./images/yellow-house.png')}`} alt="" />
              <img src={`${require('./images/blue-house.png')}`} alt="" />
            </aside>
            <aside className='titleContainer'>
              <h2>eppraisal</h2>
            </aside>
          </div>
          {valueRange}
          <div className='lds-ellipsis'><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    );  
  }
}